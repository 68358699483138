import { ApiClient } from "../helpers/api-client";

const url = process.env.VUE_APP_API_URL;
const apiClient = new ApiClient();

const urunlerListesi = async () => {
  return await apiClient.makeRequest({
    url: `${url}/urunler`,
  });
};

const urunlerBilgisi = async (id) => {
  return await apiClient.makeRequest({
    url: `${url}/urun/${id}`,
  });
};

const urunKayit = async (barkod, urunAdi, fiyat, resim) => {
  return await apiClient.makeRequest({
    url: `${url}/urun_kayit`,
    json: {
      barkod,
      urunAdi,
      fiyat,
      resim,
    },
  });
};

const urunGuncelle = async (barkod, urunAdi, fiyat, resim, id) => {
  return await apiClient.makeRequest({
    url: `${url}/urun_guncelle/${id}`,
    json: {
      barkod,
      urunAdi,
      fiyat,
      resim,
    },
  });
};

const urunSil = async (id) => {
  return await apiClient.makeRequest({
    url: `${url}/urun_sil/${id}`,
  });
};

export { urunlerListesi, urunlerBilgisi, urunKayit, urunGuncelle, urunSil };
