<template>
  <div class="text-center">
    <v-dialog persistent v-model="crudDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ cardText }}
          <v-spacer />
          <v-avatar size="60">
            <v-img max-height="500" max-width="500" :src="urunBilgisi.resim" />
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="urunBilgisi.barkod"
            name="barkod"
            id="barkod"
            label="Barkod"
          />
          <v-text-field
            v-model="urunBilgisi.urunAdi"
            name="urunAdi"
            id="urunAdi"
            label="Ürün Adı"
          />
          <v-text-field
            v-model="urunBilgisi.fiyat"
            name="fiyat"
            id="fiyat"
            label="Fiyat"
          />
          Resim
          <v-file-input @change="resim_degisti" truncate-length="15" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" @click="$emit('hideDialog')"> Vazgeç </v-btn>
          <v-btn color="primary" @click="crud"> {{ buttonText }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { urunlerBilgisi, urunGuncelle, urunKayit } from "../../query/urunler";

export default {
  watch: {
    crudDialog() {
      this.crudFunction(this.urunId);
    },
  },

  props: { crudDialog: null, urunId: null },

  data() {
    return {
      urunBilgisi: [],
      buttonText: "Kaydet",
      cardText: "Yeni Ürün",
      crud: this.kaydet,
    };
  },

  methods: {
    async crudFunction(urunId) {
      if (this.urunId != null) {
        this.urunBilgisi = await urunlerBilgisi(urunId);
        this.buttonText = "Güncelle";
        this.cardText = "Bilgileri Güncelle";
        this.crud = this.guncelle;
      } else {
        this.urunBilgisi = [];
        this.buttonText = "Kaydet";
        this.cardText = "Yeni Ürün";
        this.crud = this.kaydet;
      }
    },

    async kaydet() {
      const sonuc = await urunKayit(
        this.urunBilgisi.barkod,
        this.urunBilgisi.urunAdi,
        this.urunBilgisi.fiyat,
        this.urunBilgisi.resim
      );
      if (sonuc !== undefined) {
        this.$emit("hideDialog");
      }
    },

    async guncelle() {
      const sonuc = await urunGuncelle(
        this.urunBilgisi.barkod,
        this.urunBilgisi.urunAdi,
        this.urunBilgisi.fiyat,
        this.urunBilgisi.resim,
        this.urunId
      );

      if (sonuc !== undefined) {
        this.$emit("hideDialog");
      }
    },

    resim_degisti(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.urunBilgisi.resim = reader.result;
      };
    },
  },
};
</script>
