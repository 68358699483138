<template>
  <layout>
    <template slot="main">
      <v-card>
        <crud
          :crudDialog="tetikle"
          :urunId="urunId"
          @hideDialog="showClose()"
        />
        <v-card-title>
          Ürünler
          <v-spacer />
          <v-btn
            color="primary"
            @click="
              tetikle = true;
              urunId = null;
            "
          >
            Yeni Ürün
          </v-btn>
        </v-card-title>
        <datatables :headers="headers" :items="urunler">
          <template v-slot:dt_actions="{ item }">
            <v-icon small class="mr-2" @click="updateDialog(item.id)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="silDialog(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:dt_resim="{ item }">
            <v-img max-height="50" max-width="50" :src="item.resim" />
          </template>
        </datatables>
      </v-card>
    </template>
  </layout>
</template>

<script>
import Crud from "./Crud.vue";
import Datatables from "@/components/datatables/index.vue";
import { urunlerListesi, urunSil } from "../../query/urunler";
import Layout from "@/components/Layout";
export default {
  components: { Crud, Datatables, Layout },

  async mounted() {
    this.urunler = await urunlerListesi();
  },

  methods: {
    showClose() {
      this.tetikle = false;
    },

    async silDialog(item) {
      const result = await this.$dialog.error({
        text: "Kayıt silinsin mi?",
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Evet",
          },
          false: "Hayır",
        },
      });
      if (result) {
        const sonuc = await urunSil(item.id);
        if (sonuc !== undefined) {
          this.urunler = await urunlerListesi();
        }
      }
    },

    updateDialog(item) {
      this.urunId = item;
      this.tetikle = true;
    },
  },

  watch: {
    async tetikle(val) {
      if (val === false) {
        this.urunler = await urunlerListesi();
      }
    },
  },

  data() {
    return {
      urunler: [],
      tetikle: false,
      urunId: null,
      options: {},
      headers: [
        {
          text: "Barkod",
          value: "barkod",
        },
        {
          text: "Ürün Adı",
          value: "urunAdi",
        },
        {
          text: "Fiyat",
          value: "fiyat",
        },
        {
          text: "Resim",
          value: "resim",
          hasSlot: true,
        },
        {
          text: "İşlemler",
          value: "actions",
          hasSlot: true,
        },
      ],
    };
  },
};
</script>
